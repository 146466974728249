import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GetStarted from "../components/sections/GetStarted";

import hero from "../images/samsung-pay/samsung-pay-hero.png";
import section1 from "../images/samsung-pay/Illustration-samsung-pay-1.png";
import section2 from "../images/samsung-pay/Illustration-samsung-pay-2.png";
import card1 from "../images/samsung-pay/section-img-1.png";
import card2 from "../images/samsung-pay/section-img-2.png";
import {Link} from "gatsby";

const SamsungPay = () => {
    return (
        <Layout hiddenContactBtn>
            <SEO
                title="Cards - Samsung Pay - Digimeth.net"
                description="Make the payment flow pleasant with PSP Digimeth. Integrate Samsung Pay and allow your customers to pay in one click."
            />
            <article>
                <section
                    className="typical__padding"
                    style={{paddingTop: `40px`, paddingBottom: `120px`}}
                >
                    <div className="site__container grid" style={{justifyContent: 'space-between'}}>
                        <div className="left">
                            <div className="content--wrapper">
                                <div className="content" style={{maxWidth: `600px`}}>
                                    <h2 style={{marginBottom: `20px`, width: '637px'}}>
                    <span className="default">
                      Enjoy simple and secure payments with
                    </span>
                                        <span style={{color: '#1E4BC6' }}> Samsung Pay</span>
                                    </h2>
                                    <h3 className="description">
                                        Samsung Pay™ is an instant payment method from Samsung. It enables users to pay in
                                        one click. Moreover the card data is securely stored by Samsung.
                                    </h3>
                                    <br/>
                                    <small
                                        style={{
                                            maxWidth: `530px`,
                                            width: `100%`,
                                            display: `inline-block`,
                                        }}
                                    >
                                        Enjoy Samsung Pay payment experience in different web browsers or with any Samsung device
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <picture className="fluid__wrapper">
                                <img alt="hero" src={hero}/>
                            </picture>
                        </div>
                    </div>
                </section>
                <section className="alternative_payment-section typical__padding co-bg--white">
                    <div className="site__container">
                        <div className="customer-container">
                            <div className="customer-container-card">
                                <div className="content" >
                                    <div className="side left">
                                        <div className="customer-cover" >
                                            <div className="content-wrapper-box">
                                                <p>How does it work?</p>
                                                <h3>
                                                    Customer either already has or adds a credit or debit
                                                    card to the Samsung Wallet™ app.
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side right">
                                        <div className="customer-cover" >
                                            <div className="content-wrapper-box">
                                                <picture>
                                                    <img src={section1} alt="connect"/>
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-container-card">
                                <div className="content" >
                                    <div className="side left">
                                        <div className="customer-cover" >
                                            <div className="content-wrapper-box">
                                                <h3>
                                                    Сustomer taps the Samsung Pay button and makes a
                                                    transaction.
                                                </h3>
                                                <p>
                                                    That’s it – no need to fill out forms or enter payment
                                                    info!
                                                </p>
                                                <Link
                                                    to="/contact"
                                                    style={{marginTop: `20px`, marginBottom: `20px`, display: `inline-block`}}
                                                >
                                                    <button
                                                        type="button"
                                                        className="digimeth-btn default-btn xl"
                                                    >
                                                        Start integration
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side right">
                                        <div className="customer-cover" >
                                            <div className="content-wrapper-box">
                                                <picture>
                                                    <img src={section2} alt="connect"/>
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="secondary_payment-section typical__padding co-bg--grey mt-100">
                    <div className="site__container " style={{paddingTop: `80px`}}>
                        <h2 className="secodary-title">
                            How to integrate Samsung Pay to your website
                        </h2>
                        <p
                            className="secondary-description"
                            style={{maxWidth: `530px`, width: `100%`, margin: `0 auto`}}
                        >
                            Samsung Pay can be integrated directly via Samsung Pay API or
                            through the Digimeth payment page
                        </p>
                        <div className="connection-container">
                            <div className="connection-container-card">
                                <div className="content">
                                    <div className="top">
                                        <h3 className="title">Connection with Samsung Pay API</h3>
                                        <p className="description">
                                            You can place the Samsung Pay button directly on your
                                            website. Your website should use a HTTPS connection and
                                            you need to perform additional steps to
                                            get started with the Samsung Pay API. Please find more
                                            information and the instructions via the
                                            <a
                                                href="https://developer.samsung.com/pay/web/overview.html"
                                                target="_blank"
                                                style={{textDecoration: `none`, color: `#437873`}}
                                                rel="noreferrer"
                                            >
                                                {" "}
                                                link
                                            </a>
                                            .
                                        </p>
                                    </div>
                                    <picture>
                                        <img src={card1} alt="connection"/>
                                    </picture>
                                </div>
                            </div>
                            <div className="connection-container-card">
                                <div className="content">
                                    <div className="top">
                                        <h3 className="title">
                                            Connection via the Digimeth payment page
                                        </h3>
                                        <p className="description">
                                            All you need is an integration with Digimeth - there is no
                                            need for additional integrations. The Samsung Pay button
                                            will be on the Digimeth payment page. The technical
                                            integration is also the same as for other payment methods
                                            used through the payment page
                                        </p>
                                    </div>
                                    <picture>
                                        <img src={card2} alt="connection"/>
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div className="useful-links-containder">
                            <h3 className="useful-links-title">Useful links</h3>
                            <ul className="useful-links-list">
                                <li>
                                    <a
                                        href="https://developer.samsung.com/pay/web/download.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Brand guidelines for websites
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://developer.samsung.com/privacy?location=eu"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Samsung Pay and Wallet APIs Acceptable Use Policy
                                    </a>
                                </li>
                            </ul>
                            <p>Samsung Pay and Samsung Wallet are trademarks of Samsung</p>
                        </div>
                    </div>
                </section>
                <GetStarted
                    isGetStarted={true}
                    title="Simple and secure payments with Samsung Pay"
                />
            </article>
        </Layout>
    );
};

export default SamsungPay;
